












































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import * as CommentApi from "@/api/examComment";
import { ExamSchoolStatistics } from "@/classes/exam-comment";
import * as _ from "lodash";
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";
import PanelGroup from "./components/PanelGroup.vue";

@Component({
  name: "ExamSchoolStatisticsDetails",
  components: {
    cardBox,
    PanelGroup,
  },
})
export default class extends Vue {
  private title: string = "考点分析统计详情";
  private formData: any = {}; //!: ExamSchoolStatistics;
  private loading: boolean = false;

  private get examNulls(): any[] {
    return _.get(this.formData, "examStudents", []).filter((v: any) => {
      return v!.statisticsTag.toLowerCase() == "examNull".toLowerCase();
    });
  }

  private get examFaileds(): any[] {
    return _.get(this.formData, "examStudents", []).filter((v: any) => {
      const tag = v!.statisticsTag.toLowerCase();
      const tag1 = "examNone".toLowerCase();
      const tag2 = "examHas".toLowerCase();
      return tag == tag1 || tag == tag2;
    });
  }

  private handlePanelGroupItemClick(a: string) {
    //
  }

  private exportExamNullsLoading: boolean = false;
  private doExportExamNullsClick() {
    try {
      this.exportExamFailedsLoading = true;
      const tHeader = ["班级", "考号", "姓名", "状态"];
      const filterVal = [
        "className",
        "examAccount",
        "examStudentName",
        "statisticsTag",
      ];
      const data = formatJson(filterVal, this.examNulls);
      exportJson2Excel(
        tHeader,
        data,
        `${_.get(this.formData, "examProjectName", "")}_${_.get(
          this.formData,
          "examSchoolName",
          ""
        )}_缺考名单`
      );
    } finally {
      this.exportExamFailedsLoading = false;
    }
  }

  private exportExamFailedsLoading: boolean = false;
  private doExportExamFailedsClick() {
    try {
      this.exportExamFailedsLoading = true;
      const tHeader = ["班级", "考号", "姓名", "状态", "数据包"];
      const filterVal = [
        "className",
        "examAccount",
        "examStudentName",
        "statisticsTag",
        "examAnswerPackageFileUrl",
      ];
      const data = formatJson(filterVal, this.examFaileds);
      exportJson2Excel(
        tHeader,
        data,
        `${_.get(this.formData, "examProjectName", "")}_${_.get(
          this.formData,
          "examSchoolName",
          ""
        )}_未结束考试名单`
      );
    } finally {
      this.exportExamFailedsLoading = false;
    }
  }

  private statisticsTagFormatter(row: any, column: any) {
    switch (row.statisticsTag.toLowerCase()) {
      case "examNone".toLowerCase(): {
        return "已重置";
      }
      case "examHas".toLowerCase(): {
        return "未结束";
      }
      case "examNull".toLowerCase(): {
        return "缺考";
      }
      default: {
        return row.statisticsTag;
      }
    }
  }

  private examAnswerPackageFileUrlFormatter(row: any, column: any) {
    if (row.examAnswerPackageFileUrl) {
      return `有/${
        row?.examAnswerPackageDetailFileNames
          ? row.examAnswerPackageDetailFileNames.length
          : "N"
      }`;
    }
    return "无";
  }

  private async refreshData(examProjectId: string, examSchoolId: string) {
    try {
      this.loading = true;
      const { data } = await CommentApi.getExamSchoolStatistics(
        examProjectId,
        examSchoolId
      );
      this.formData = data;
      this.title = `${this.formData.examProjectName} -> ${this.formData.examSchoolName}`;
    } finally {
      this.loading = false;
    }
  }
  mounted() {
    const examProjectId = (this.$route.query.examProjectId as string) || "None";
    const examSchoolId = (this.$route.query.examSchoolId as string) || "None";
    this.refreshData(examProjectId, examSchoolId);
  }
  private doGoBackClick() {
    this.$router.go(-1);
  }
}
