






















































































import { Component, Prop, Vue } from "vue-property-decorator";
import * as _ from "lodash";
import CountTo from "vue-count-to";

@Component({
  name: "PanelGroup",
  components: {
    CountTo,
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  private formData!: any;
  private handleItemClick(type: string) {
    this.$emit("handleItemClick", type);
  }
  private get examStudentCount(): number {
    return _.get(this.formData, "examStudentCount", 0);
  }
  private get examNull(): number {
    return _.get(this.formData, "examNull", 0);
  }
  private get examEnded(): number {
    return _.get(this.formData, "examEnded", 0);
  }
  private get pkgCount(): number {
    return _.get(this.formData, "pkgCount", 0);
  }
  private get examFailed(): number {
    return (
      _.get(this.formData, "examNone", 0) + _.get(this.formData, "examHas", 0)
    );
  }
}
